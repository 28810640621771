<template>
    <div class="box">
       <router-link
                v-if="$can('admin/classify/create')"
                :to="{ path: '/classify/create' }"
            >
                <el-button
                    type="primary"
                    size="medium"
                >
                    新建分类
                </el-button>
            </router-link>
        <div class="box-toolbar">

             <div class="box-item">
        <el-input
          placeholder="分类名称"
          v-model="classifyName"
          clearable
          @keyup.enter.native="searchGo"
        >
        </el-input>
      </div>
      <div class="box-item">
        <el-button
          icon="el-icon-search"
          circle
          @click="searchGo()"
        ></el-button>
      </div>
        </div>
        <el-table
            border
            v-loading="loading"
            :stripe="true"
            :data="lists"
            height="75vh"
        >
            <el-table-column
                prop="classifyName"
                label="分类名称"
            >
            </el-table-column>

            <el-table-column
                prop="classifyName"
                label="是否全局"
            >
             <template slot-scope="scope">
               {{scope.row.isGlobal == 1?'是':'否'}}
            </template>
            </el-table-column>

             <el-table-column
                prop="classifyPosition"
                label="显示顺序"
            >
            </el-table-column>

            <!-- <el-table-column
                label="店铺启用情况"
            >
            <template slot-scope="scope">
                <enable @changeStatus="changeStatus" :items="scope.row.shops"></enable>
            </template>
            </el-table-column> -->

            <el-table-column
                label="封面"
                width="100"
            >
            <template slot-scope="scope">
                <img class="iimg" @click="dialog(scope.row.classifyCover)" :src="scope.row.classifyCover" alt="">
            </template>
            </el-table-column>
            <el-table-column
                width="100"
                label="操作"
            >
                <template slot-scope="scope">
                    <el-dropdown trigger="click">
                        <el-button
                            plain
                            size="mini"
                            type="primary"
                        >
                            操作<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                v-if="$can('admin/classify/update')"
                                @click.native="handleClassifyEdit(scope.row)"
                            >
                                编辑
                            </el-dropdown-item>
                            <el-dropdown-item
                                v-if="$can('admin/classify/delete')"
                                @click.native="handleClassifyDelete(scope.row)"
                            >
                                删除
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column>
        </el-table>
         <page
    :pageSize="limit"
    :count="count"
    @change="pageChange"
    @changeSize="changeSize"
    >
    </page>
    <el-dialog
      :visible.sync="dialogVisible"
      width="400"
    >
    <div
      class="dialog-img"
    >
    <img :src="dialogImgPath" alt="">
    </div>
     </el-dialog>
    </div>
</template>

<script>
import ClassifyService from '@admin/services/ClassifyService'
import flatry from '@admin/utils/flatry'
// import enable from './components/_EnableStatus'
import Page from '@admin/components/Pagination'
import pagination from '@admin/mixins/PaginationMixin'

export default {
  components: {
    Page
    // enable
  },
  data () {
    return {
      dialogVisible: false,
      dialogImgPath: '',
      loading: true,
      function: function (param) {
        return ClassifyService.all(param)
      },
      classifyName: ''
    }
  },

  mixins: [pagination],
  methods: {
    async searchGo () {
      this.page = 1
      let param = {
        classifyName: this.classifyName
      }
      this.where = param
      await this.getList(param)
    },
    // 更改选中的状态
    async changeStatus (item) {
      const { data } = await flatry(ClassifyService.changeStatus(item))
      if (data) {
        this.$message({
          type: 'success',
          message: data.msg
        })
      }
    },
    dialog (imgPath) {
      this.dialogVisible = true
      this.dialogImgPath = imgPath
    },
    handleClassifyEdit (Classifys) {
      this.$router.push({ path: '/classify/update', query: { id: Classifys.id } })
    },
    handleClassifyDelete (Classifys) {
      this.$deleteDialog(`商品分类 ${Classifys.classifyName}`, async (action) => {
        if (action === 'confirm') {
          this.loading = true

          const { data } = await flatry(ClassifyService.delete(Classifys.id))

          if (data) {
            this.lists.forEach((item, index) => {
              if (item.id === Classifys.id) {
                this.lists.splice(index, 1)
              }
            })

            this.$message({
              type: 'success',
              message: data.msg
            })
          }

          this.loading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.iimg{
    width: 50px;
    height: 50px;
    object-fit: cover;
}
</style>
